<template>
  <v-hover>
    <DefaultButton
      v-if="minimal"
      class="phone-button"
      slot-scope="{ hover }"
      :style="{ height: `${buttonSize}px`, width: `${buttonSize}px` }"
      icon
      small
      :color="hover ? 'primary' : 'white'"
      :bgColor="hover ? 'border' : 'primary'"
      :href="`tel:${phoneNumber}`"
    >
      <v-icon
        :color="color"
        :style="{ verticalAlign: 'middle', fontSize: `${size}px` }"
        >{{ icon }}</v-icon
      >
    </DefaultButton>
    <DefaultButton
      v-else
      class="phone-button pr-1"
      small
      :href="`tel:${phoneNumber}`"
      style="min-width: 110px"
    >
      <v-layout column fill-height align-center justify-center>
        <v-layout align-center>
          <div class="primary--text">Zadzwoń</div>
          <v-icon
            color="primary"
            class="px-1"
            :style="{ verticalAlign: 'middle', fontSize: `${size}px` }"
            >{{ icon }}</v-icon
          >
        </v-layout>
      </v-layout>
    </DefaultButton>
  </v-hover>
</template>

<script>
export default {
  props: {
    icon: {
      default: "fa fa-phone",
    },
    minimal: {
      default: true,
    },
    size: {
      type: Number,
      default: 16,
    },
    phoneNumber: {
      type: String,
      default: "+48",
    },
  },
  computed: {
    buttonSize() {
      return this.size * 2;
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>